import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HoldingContent } from 'components/serverSideRendered/holdingContent';
import { parseAppConfig } from 'helpers/globalsAndSwitches/window';
import { getAbParticipations, setUpTrackingAndConsents, } from 'helpers/page/page';
import { renderPage } from 'helpers/rendering/render';
import { geoIds } from 'pages/geoIdConfig';
const abParticipations = getAbParticipations();
setUpTrackingAndConsents(abParticipations);
const appConfig = parseAppConfig(window.guardian);
const Checkout = lazy(() => {
    return import(/* webpackChunkName: "checkout" */ './checkout').then((mod) => {
        return { default: mod.Checkout };
    });
});
const OneTimeCheckout = lazy(() => {
    return import(
    /* webpackChunkName: "oneTimeCheckout" */ './oneTimeCheckout').then((mod) => {
        return { default: mod.OneTimeCheckout };
    });
});
const ThankYou = lazy(() => {
    return import(/* webpackChunkName: "ThankYou" */ './thankYou').then((mod) => {
        return { default: mod.ThankYou };
    });
});
const GuardianAdLiteLanding = lazy(() => {
    return import(
    /* webpackChunkName: "GuardianAdLiteLanding" */ './guardianAdLiteLanding/guardianAdLiteLanding').then((mod) => {
        return { default: mod.GuardianAdLiteLanding };
    });
});
const LandingPage = lazy(() => {
    return import(/* webpackChunkName: "LandingPage" */ './landingPage').then((mod) => {
        return { default: mod.LandingPage };
    });
});
const router = createBrowserRouter(geoIds.flatMap((geoId) => [
    {
        path: `/${geoId}/contribute/:campaignCode?`,
        element: (_jsx(Suspense, { fallback: _jsx(HoldingContent, {}), children: _jsx(LandingPage, { geoId: geoId, abParticipations: abParticipations }) })),
    },
    {
        path: `/${geoId}/checkout`,
        element: (_jsx(Suspense, { fallback: _jsx(HoldingContent, {}), children: _jsx(Checkout, { geoId: geoId, appConfig: appConfig, abParticipations: abParticipations }) })),
    },
    {
        path: `/${geoId}/one-time-checkout`,
        element: (_jsx(Suspense, { fallback: _jsx(HoldingContent, {}), children: _jsx(OneTimeCheckout, { geoId: geoId, appConfig: appConfig, abParticipations: abParticipations }) })),
    },
    {
        path: `/${geoId}/thank-you`,
        element: (_jsx(Suspense, { fallback: _jsx(HoldingContent, {}), children: _jsx(ThankYou, { geoId: geoId, appConfig: appConfig, abParticipations: abParticipations }) })),
    },
    {
        path: `/${geoId}/guardian-ad-lite`,
        element: (_jsx(Suspense, { fallback: _jsx(HoldingContent, {}), children: _jsx(GuardianAdLiteLanding, { geoId: geoId }) })),
    },
]));
function Router() {
    return _jsx(RouterProvider, { router: router });
}
export default renderPage(_jsx(Router, {}));
