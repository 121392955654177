import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css, Global } from '@emotion/react';
import { from, neutral, palette, space, textSans17, until, } from '@guardian/source/foundations';
import { Column, Columns } from '@guardian/source/react-components';
import { FooterLinks, FooterWithContents, } from '@guardian/source-development-kitchen/react-components';
import { PrerenderGlobalStyles } from '../../helpers/rendering/prerenderGlobalStyles';
import { guardianFonts } from '../../stylesheets/emotion/fonts';
import { reset } from '../../stylesheets/emotion/reset';
import { CheckoutHeading } from '../checkoutHeading/checkoutHeading';
import { Header } from '../headers/simpleHeader/simpleHeader';
import { Container } from '../layout/container';
import { SkipLink } from '../skipLink/skipLink';
import AnimatedDots from '../spinners/animatedDots';
const checkoutContainer = css `
	position: relative;
	color: ${neutral[7]};
	${textSans17};

	padding-top: ${space[3]}px;
	padding-bottom: ${space[9]}px;

	${from.tablet} {
		padding-bottom: ${space[12]}px;
	}

	${from.desktop} {
		padding-top: ${space[6]}px;
	}
`;
const darkBackgroundContainerMobile = css `
	background-color: ${palette.neutral[97]};
	${until.tablet} {
		background-color: ${palette.brand[400]};
		border-bottom: 1px solid ${palette.brand[600]};
	}
`;
const container = css `
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-width: 100%;
	overflow-x: hidden;
	background-color: ${palette.brand[400]};

	& main {
		flex: 1;
		display: flex;
		flex-direction: column;
		& > :last-child {
			flex: 1;
		}
	}
`;
export function HoldingContent() {
    return (_jsxs("div", { css: container, children: [_jsx(Global, { styles: [reset, guardianFonts] }), _jsxs("nav", { "aria-label": "Skip to section", children: [_jsx(SkipLink, { id: "maincontent", label: "Skip to main content" }), _jsx(SkipLink, { id: "navigation", label: "Skip to navigation" })] }), _jsx(Header, {}), _jsxs("main", { role: "main", id: "maincontent", children: [_jsx(PrerenderGlobalStyles, {}), _jsx(CheckoutHeading, {}), _jsx(Container, { sideBorders: true, cssOverrides: darkBackgroundContainerMobile, children: _jsxs(Columns, { cssOverrides: checkoutContainer, collapseUntil: "tablet", children: [_jsx(Column, { span: [0, 2, 5] }), _jsx(Column, { span: [1, 8, 7] })] }) }), _jsxs("div", { children: [_jsx("p", { children: "Loading\u2026" }), _jsx(AnimatedDots, { appearance: "dark" })] })] }), _jsx(FooterWithContents, { children: _jsx(FooterLinks, {}) })] }));
}
